@import '~modern-normalize/modern-normalize.css';
@import '~@dreipol/pusch-components/lib/index.css';

//donation form styling
:root {
  --tamaro-primary-color: #2d8536;
  --tamaro-primary-color__hover: #2d8536;
}

#tamaro-widget-overlay.tamaro-widget.tamaro-widget,
#tamaro-widget.tamaro-widget.tamaro-widget {
  font-family: inherit;
}

//global scrolling behavior
html {
  scroll-behavior: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

//google recaptcha styling
.grecaptcha-badge {
  visibility: hidden;
}

//next-img fixes
@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading='lazy'] {
    clip-path: inset(0.6px);
  }
}
